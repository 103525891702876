<template>
  <div>
    <div class="outerContainer">
      <b-container>
        <h2 class="allPlayers" :class="{'adtColour' : develop == 'adt'}">All Players</h2>
        <b-row>
          <b-col>
            <!-- <input type="text" id="myInput" onkeyup="myFunction()" placeholder="Search for names.." title="Type in a name"> -->
            <b-form-group label-for="filter-input">
              <b-input-group size="md">
                <b-form-input
                  id="filter-input"
                  type="search"
                  placeholder="Search Player..."
                  v-model="search"
                ></b-form-input>
                  <div>
                    <img class="searchMag" src="https://assets.asiantour.com/asian-tour/2023/12/Icon_feather-search.png">
                  </div>
                <!-- <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''"
                    >Clear</b-button
                  >
                </b-input-group-append> -->
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div >
      <table class="tableInfo container" id="myTable" >
        <thead>
          <tr class="" :class="{'adtColourBK' : develop == 'adt'}">
            <th class="firstWidth"></th>
            <th>Name</th>
            <th>Nat</th>
            <th class="lastWidth"></th>
          </tr>
        </thead>
        <tbody v-for="(letter, index) in letters" :key="index">
            <tr :class="{'adtDiver' : develop == 'adt'}" class="monthRow containe letterAlpha"  v-if="search == ''"> 
              <td colspan="11">{{ letter }}</td>
            </tr>
          <tr v-for="(item, index) in alphaContacts(letter)" :key="index" >
            <td class="firstWidth">
              <template v-if="item.PHOTOS.PHOTO.PHOTO_IMAGE == 'p_nul.gif'">
                <template v-if="develop == 'adt'">
                  <b-img
                    class="player"
                    :src="'https://assets.asiantour.com/asian-tour/2024/05/User_Gradient_LandscapeGreen-1.png'"
                  ></b-img>
                </template>
                <template v-else>
                  <b-img
                    class="player"
                    :src="'https://assets.asiantour.com/asian-tour/2023/11/User_Gradient_LandscapeBLUE.png'"
                  ></b-img>
                </template>
              </template>
              <template v-else>
                <img class="player" :src="'https://ocs-asia.sgp1.cdn.digitaloceanspaces.com/apga/media/photos/' + item.PHOTOS.PHOTO.PHOTO_IMAGE">
              </template>
            </td>
            <td class="LeftSection">
              <template v-if="item.PROFILE == 'Y'">
                <template v-if="develop == 'adt'">
                  <a :href="'/adt/playerprofile/' + item.REFNO + '/'" class="adtcolorLink">
                    {{ item.FORENAME_1 }} {{ item.SURNAME }}
                  </a>
                </template>
                <template v-else>
                  <a :href="'/playerprofile/' + item.REFNO + '/'" class="linkName">
                    {{ item.FORENAME_1 }} {{ item.SURNAME }}
                  </a>
                </template>
              </template>
              <template v-else>
                  {{ item.FORENAME_1 }} {{ item.SURNAME }}
              </template>
            </td>
            <td class="LeftSection">
              <img
                :class="flag"
                :src="(config.VUE_APP_FLAG_URL + item.NAT) | lowercase"
              />
            </td>
            <td class="lastWidth">
              <template v-if="item.PROFILE == 'Y'">
                <template v-if="develop == 'adt'">
                  <b-link :to="'/adt/playerprofile/' + item.REFNO + '/'" class="linkName">
                    <font-awesome-icon class="icon arrowIcon" :icon="['fa', 'chevron-right']" />
                  </b-link>
                </template>
                <template v-else>
                  <b-link :to="'/playerprofile/' + item.REFNO + '/'" class="linkName">
                    <font-awesome-icon class="icon arrowIcon" :icon="['fa', 'chevron-right']" />
                  </b-link>
                </template>
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: ["season", "config", 'DEFIMAGESAMS3', 'develop'],
  data() {
    return {
      items: [],
      details: "details",
      cell: "cell",
      flag: "flag",
      selected: null,
      fields: [
        { key: "ref_no", label: "", class: "imageBlock-header" },
        { key: "name", label: "Name", class: "Fullname" },
        { key: "nat", label: "Nationality", class: "text-center" },
        { key: "actions", label: "" },
      ],
      totalRows: 1,
      currentPage: 1,
      filter: null,
      filterOn: [],
      search: '',
      letters: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')
    };
  },
  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }

      return value.toLowerCase() + ".svg";
    },
  },
  methods: {
    // myFunction: function() {
    //   console.log('IN Function')
    //   var input, filter, table, tr, td, i, txtValue;
    //   input = document.getElementById("myInput");
    //   filter = input.value.toUpperCase();
    //   table = document.getElementById("myTable");
    //   tr = table.getElementsByTagName("tr");
    //   for (i = 0; i < tr.length; i++) {
    //     td = tr[i].getElementsByTagName("td")[0];
    //     if (td) {
    //       txtValue = td.textContent || td.innerText;
    //       if (txtValue.toUpperCase().indexOf(filter) > -1) {
    //         tr[i].style.display = "";
    //       } else {
    //         tr[i].style.display = "none";
    //       }
    //     }       
    //   }
    // },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    alphaContacts: function(l) {
      return this.items.filter(i => {
        return i.FULL_NAME.toLowerCase().indexOf(l.toLowerCase()) === 0 && i.FULL_NAME.toLowerCase().includes(this.search.toLowerCase());
      });
    }
  },
  mounted() {
      axios //add API Call
        .get('https://api.asia.ocs-software.com/apga/cache/apga/profiles/index_ASN?randomadd=1673355634974') // Page number changed on each page based on JSON page id you are calling
        .then((response) => (this.items = response.data.MEMBERS.PLAYER));
      // this.myFunction();
  },
};
</script>

<style scoped>
a.adtcolorLink:hover {
  color: #1c857c;
  text-decoration: none;
  text-align: left;
  font-size: 12px;
  font-weight: 500;
}
a.adtcolorLink {
  color: #7E7E7E;
  text-align: left;
  font-size: 12px;
  font-weight: 500;
}
.adtDiver {
  background-color: #1c857c!important;
}
.adtColourBK > th{
  background-color: #76c3bd;
}
.adtColour {
  color: #76c3bd!important;
}
.playerDiv {
  background-color: #F5F5F5;
}
img.searchMag {
  margin-top: 14px;
  margin-right: 35px;
  z-index: 999;
  position: absolute;
  right: 0;
}
.outerContainer > .container {
  padding-left: 0;
  padding-right: 0;
}
tr.monthRow.containe.letterAlpha > td {
  padding-left: 80px;
  text-align: left;
  padding-top: 12px;
  padding-bottom: 12px;
  color: #fff;
}
tr.monthRow.containe.letterAlpha {
  color: #fff;
}
.firstWidth {
  text-align: left;
  padding-left: 60px;
}
.lastWidth {

}
.letterAlpha {
  background: #A8B7C9;
  font-size: 13px;
  font-weight: bold;
  padding-top: 15px;
  padding-bottom: 15px;
}
.letterPos.container {
  background: #A8B7C9;
  color: #fff;
  padding-left: 80px;
}
tr.monthRow.container > td{
  text-align: left;
  padding-left: 14.5%;
}
.LeftSection {
  text-align: left;
}
.tableInfo {
  width: 100%;
  font-size: 14px;
}
svg.icon.arrowIcon.svg-inline--fa.fa-chevron-right.fa-w-10 {
  color: #A8B7C9;
  cursor: pointer;
}
svg.icon.arrowIcon.svg-inline--fa.fa-chevron-right.fa-w-10:hover {
  color: #41a2f6;
}
a.linkName {
  color: #0A3F7F!important;
  text-align: left;
  font-size: 12px;
  font-weight: 500;
}
a.linkName:hover {
  color: #41a2f6!important;
  text-decoration: none;
}
.form-group {
  margin-bottom: 60px;
}
.outerContainer {
  background-color: #F5F5F5;
}
.allPlayers {
  color: #0A3F7F;
  font-size: 20px;
  font-weight: 500;
  padding-top: 50px;
  padding-bottom: 30px;
}
::v-deep input#filter-input {
  border-radius: 0;
  border: 0px solid #c7c7cc;
  height: 43px;
  font-size: 16px;
  width: 100%;
}
::v-deep td.Fullname {
  font-size: 14px;
  font-weight: 500;
  color: #337ab7;
  text-align: left;
}
::v-deep td.Fullname > div {
  position: relative;
  top: 8px;
}
::v-deep th.text-center {
  /* border-radius: 0px 10px 0 0 !important; */
}
::v-deep th.imageBlock-header {
  /* border-radius: 10px 0px 0 0 !important; */
}
::v-deep th {
  color: white;
  background-color: #042F62;
  width: 29%;
  text-align: left;
  position: sticky;
  top: 0;
  font-size: 12px;
  font-weight: bold;
  z-index: 999;
  padding-top: 15px;
  padding-bottom: 15px;
}
::v-deep td {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

::v-deep li {
  list-style-type: none;
}

::v-deep .nav-link {
  color: white;
  background-color: #6697e0;
  width: 140px;
}

::v-deep .btn {
  color: white;
  background-color: #6697e0;
}

.playerPhoto {
  width: 75%;
}

.flag {
  width: 40px;
  border: 0.25px solid #e2e1e1;
  position: relative;
  top: 0px;
}

.profileButton {
  padding-left: 5%;
}

.details {
  padding-top: 3%;
}
::v-deep tr:nth-child(even) {
  background-color: #fff;
}
.player {
  width: 50px;
}
@media only screen and (max-width: 480px) {
  ::v-deep td.Fullname > div {
    position: relative;
    top: 10px;
  }
  ::v-deep .nav-link {
    width: 35%!important;
  }
  .playerPhoto {
    width: 130px;
  }
  ::v-deep td.imageBlock-header {
    width: 31%;
  }
  .player {
    width: 70px!important;
  }
}
@media only screen and (max-width: 767.98px) {
  ::v-deep th {
    color: white;
    background-color: #042F62;
    width: 29%;
    text-align: left;
    position: sticky;
    top: 0;
    font-size: 12px;
    font-weight: bold;
    z-index: 9!important;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .player {
    width: 100%;
  }
  ::v-deep .table-sm th,
  .table-sm td {
    padding: 2.3rem;
    padding-top: 1em;
    padding-bottom: 1em;
  }
  ::v-deep .table.b-table.b-table-stacked-md > tbody > tr > :first-child {
    border-top-width: 0px;
  }
}
@media only screen and (max-width: 768px) {
    img.searchMag {
    margin-top: 14px;
    margin-right: 35px;
    z-index: 9;
    position: absolute;
    right: 0;
  }
  ::v-deep tr.monthRow.containe.letterAlpha > td {
    padding-left: 40px;
    text-align: left;
  }
  .flag {
    width: 40px;
    border: 0.25px solid #e2e1e1;
    position: relative;
    top: 0px;
  }
  .playerPhoto {
    width: 130px;
  }
  .player {
    width: 100%;
  }
  ::v-deep thead {
    display: table-header-group !important;
  }
  ::v-deep .firstWidth {
    text-align: left;
    padding-left: 20px;
    width: 30%;
  }
  ::v-deep th {
    /* width: 95px; */
    width: 50px;
    font-size: 12px;
  }
  ::v-deep td:before {
    display: none !important;
  }
  ::v-deep td:after {
    display: none !important;
  }
  ::v-deep td {
    display: table-cell !important;
    font-size: 0.8rem;
  }
  .nav-item > .nav-link {
    font-size: 0.8rem;
    width: 75px;
  }
  ::v-deep td > div {
    overflow: hidden;
    height: 80px;
    width: 100px !important;
  }
}
</style>
